import axios from '../services/axios';
import { clearStorage, getStorage, removeStorage, setStorage } from '../services/asyncStorage';
import { API_ROUTE, STORAGE } from '../configs/constants';

const authService = {
  async login(username, password) {
    const user = await axios.post(API_ROUTE.AUTH.LOGIN, { username, password });
    setStorage(STORAGE.APP_AUTHENTICATE_TOKEN, user.accessToken);

    return user;
  },
  async getUser() {
    let user = null;
    try {
      const token = getStorage(STORAGE.APP_AUTHENTICATE_TOKEN);
      if (token) {
        user = await axios.get(API_ROUTE.AUTH.PROFILE);
      }
    } catch (e) {}

    return user;
  },
  async viewAsCompany(companyId) {
    setStorage(STORAGE.APP_VIEW_COMPANY, companyId);

    return this.getUser();
  },
  async unViewCompany() {
    removeStorage(STORAGE.APP_VIEW_COMPANY);

    return true;
  },
  async updateGeneral(data) {
    return axios.put(API_ROUTE.AUTH.UPDATE_GENERAL, data);
  },
  async changePassword(data) {
    return axios.put(API_ROUTE.AUTH.CHANGE_PASSWORD, data);
  },
  logout() {
    clearStorage();
  },
};

export default authService;
