import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import settingService from '../../api/settingService';
import { DEFAULT_LANG } from '../../configs/constants';

export const actGetLanguage = createAsyncThunk('setting/getLanguage', async () => settingService.getLang());

export const actChangeLanguage = createAsyncThunk('setting/changeLanguage', async ({ lang }) =>
  settingService.changeLanguage(lang),
);

export const settingSlice = createSlice({
  name: 'auth',
  initialState: {
    lang: DEFAULT_LANG,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actGetLanguage.fulfilled, (state, action) => {
      state.lang = action.payload;
    });
    builder.addCase(actChangeLanguage.fulfilled, (state, action) => {
      state.lang = action.payload;
    });
  },
});

export default settingSlice.reducer;
