import React from 'react';
import * as Icon from 'react-feather';
import { API_METHOD, API_ROUTE, ROUTES, USER } from './constants';

const navigationConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    navLink: ROUTES.DASHBOARD,
    public: true,
  },
  {
    id: 'company',
    title: 'Công ty',
    type: 'item',
    icon: <Icon.Airplay size={20} />,
    navLink: ROUTES.COMPANY.MANAGE.HOME,
    permissions: [USER.ROLES.ADMIN, USER.ROLES.COMPANY_ADMIN],
    api: [
      { method: API_METHOD.GET, route: API_ROUTE.COMPANY.MANAGE.LIST },
      { method: API_METHOD.DELETE, route: API_ROUTE.COMPANY.MANAGE.DELETE(':id') },
    ],
    parentOf: [
      {
        id: 'companyManageCreate',
        title: 'Tạo công ty',
        navLink: ROUTES.COMPANY.MANAGE.CREATE,
        api: [{ method: API_METHOD.POST, route: API_ROUTE.COMPANY.MANAGE.CREATE }],
      },
      {
        id: 'companyManageDetail',
        title: 'Xem công ty',
        navLink: ROUTES.COMPANY.MANAGE.DETAIL(':id'),
        api: [{ method: API_METHOD.GET, route: API_ROUTE.COMPANY.MANAGE.DETAIL(':id') }],
      },
      {
        id: 'companyManageEdit',
        title: 'Sửa công ty',
        navLink: ROUTES.COMPANY.MANAGE.EDIT(':id'),
        api: [{ method: API_METHOD.PUT, route: API_ROUTE.COMPANY.MANAGE.EDIT(':id') }],
      },

      // User List Company
      {
        id: 'userListCompanyManage',
        title: 'List user company',
        navLink: ROUTES.COMPANY.MANAGE.USER(':companyId').LIST,
        api: [
          { method: API_METHOD.GET, route: API_ROUTE.COMPANY.MANAGE.LIST_ROLE },
          { method: API_METHOD.GET, route: API_ROUTE.COMPANY.MANAGE.USER(':companyId').LIST },
          { method: API_METHOD.GET, route: API_ROUTE.COMPANY.MANAGE.USER(':companyId').EXPORT },
          { method: API_METHOD.POST, route: API_ROUTE.COMPANY.MANAGE.USER(':companyId').MULTI_DELETE },
          { method: API_METHOD.DELETE, route: API_ROUTE.COMPANY.MANAGE.USER(':companyId').LOCK(':id') },
        ],
      },
      {
        id: 'userListCompanyManageCreate',
        title: 'Tạo user company',
        navLink: ROUTES.COMPANY.MANAGE.USER(':companyId').CREATE,
        api: [{ method: API_METHOD.POST, route: API_ROUTE.COMPANY.MANAGE.USER(':companyId').CREATE }],
      },
      {
        id: 'userListCompanyManageDetail',
        title: 'Xem user company',
        navLink: ROUTES.COMPANY.MANAGE.USER(':companyId').DETAIL(':id'),
        api: [{ method: API_METHOD.GET, route: API_ROUTE.COMPANY.MANAGE.USER(':companyId').DETAIL(':id') }],
      },
      {
        id: 'userListCompanyManageEdit',
        title: 'Sửa user company',
        navLink: ROUTES.COMPANY.MANAGE.USER(':companyId').EDIT(':id'),
        api: [{ method: API_METHOD.PUT, route: API_ROUTE.COMPANY.MANAGE.USER(':companyId').EDIT(':id') }],
      },
    ],
  },
  {
    id: 'news',
    title: 'Tin tức',
    type: 'item',
    icon: <Icon.Book size={20} />,
    navLink: ROUTES.NEWS.LIST,
    mustViewCompany: true,
    api: [
      { method: API_METHOD.GET, route: API_ROUTE.NEWS.LIST },
      { method: API_METHOD.GET, route: API_ROUTE.NEWS.FULL_LIST },
      { method: API_METHOD.DELETE, route: API_ROUTE.NEWS.DELETE(':id') },
    ],
    parentOf: [
      {
        id: 'newsCreate',
        title: 'Tạo tin tức',
        navLink: ROUTES.NEWS.CREATE,
        mustViewCompany: true,
        api: [{ method: API_METHOD.POST, route: API_ROUTE.NEWS.CREATE }],
      },
      {
        id: 'newsDetail',
        title: 'Xem tin tức',
        navLink: ROUTES.NEWS.DETAIL(':id'),
        mustViewCompany: true,
        api: [{ method: API_METHOD.GET, route: API_ROUTE.NEWS.DETAIL(':id') }],
      },
      {
        id: 'newsEdit',
        title: 'Sửa tin tức',
        navLink: ROUTES.NEWS.EDIT(':id'),
        mustViewCompany: true,
        api: [{ method: API_METHOD.PUT, route: API_ROUTE.NEWS.EDIT(':id') }],
      },
    ],
  },
  {
    id: 'transaction-history',
    title: 'Nhật ký canh tác',
    type: 'item',
    icon: <Icon.BookOpen size={20} />,
    navLink: ROUTES.TRANSACTION_HISTORY.LIST,
    mustViewCompany: true,
    api: [
      { method: API_METHOD.GET, route: API_ROUTE.TRANSACTION_HISTORY.LIST },
    ],
    parentOf: [
      {
        id: 'transactionHistoryDetail',
        title: 'Xem nhật ký canh tác',
        navLink: ROUTES.TRANSACTION_HISTORY.DETAIL(':id'),
        mustViewCompany: true,
        api: [{ method: API_METHOD.GET, route: API_ROUTE.TRANSACTION_HISTORY.DETAIL(':id') }],
      },
    ],
  },
  {
    id: 'tea',
    title: 'Sản phẩm',
    type: 'collapse',
    icon: <Icon.Coffee size={20} />,
    mustViewCompany: true,
    children: [
      {
        id: 'manageTea',
        title: 'Quản lý sản phẩm',
        type: 'item',
        icon: <Icon.Archive size={12} />,
        mustViewCompany: true,
        navLink: ROUTES.TEA.MANAGE.LIST,
        api: [
          { method: API_METHOD.GET, route: API_ROUTE.TEA.MANAGE.LIST },
          { method: API_METHOD.DELETE, route: API_ROUTE.TEA.MANAGE.DELETE(':id') },
        ],
        parentOf: [
          {
            id: 'createTea',
            title: 'Tạo sản phẩm',
            navLink: ROUTES.TEA.MANAGE.CREATE,
            api: [{ method: API_METHOD.POST, route: API_ROUTE.TEA.MANAGE.CREATE }],
            mustViewCompany: true,
          },
          {
            id: 'editTea',
            title: 'Sửa sản phẩm',
            navLink: ROUTES.TEA.MANAGE.EDIT(':id'),
            api: [
              { method: API_METHOD.PUT, route: API_ROUTE.TEA.MANAGE.EDIT(':id') },
              { method: API_METHOD.GET, route: API_ROUTE.TEA.MANAGE.DETAIL(':id') },
            ],
            mustViewCompany: true,
          },
        ],
      },
      {
        id: 'variantTea',
        title: 'Quản lý biến thể',
        type: 'item',
        icon: <Icon.Copy size={12} />,
        mustViewCompany: true,
        navLink: ROUTES.TEA.VARIANT.LIST,
        api: [
          { method: API_METHOD.GET, route: API_ROUTE.TEA.VARIANT.LIST },
          { method: API_METHOD.DELETE, route: API_ROUTE.TEA.VARIANT.DELETE(':id') },
        ],
        parentOf: [
          {
            id: 'createVariantTea',
            title: 'Tạo biến thể',
            navLink: ROUTES.TEA.VARIANT.CREATE,
            api: [{ method: API_METHOD.POST, route: API_ROUTE.TEA.VARIANT.CREATE }],
            mustViewCompany: true,
          },
          {
            id: 'detailVariantTea',
            title: 'Xem biến thể',
            navLink: ROUTES.TEA.VARIANT.DETAIL(':id'),
            api: [{ method: API_METHOD.GET, route: API_ROUTE.TEA.VARIANT.DETAIL(':id') }],
            mustViewCompany: true,
          },
          {
            id: 'editVariantTea',
            title: 'Sửa biến thể',
            navLink: ROUTES.TEA.VARIANT.EDIT(':id'),
            api: [{ method: API_METHOD.PUT, route: API_ROUTE.TEA.VARIANT.EDIT(':id') }],
            mustViewCompany: true,
          },
        ],
      },
    ],
  },
  {
    id: 'purchase',
    title: 'Thu mua',
    type: 'collapse',
    icon: <Icon.Archive size={20} />,
    mustViewCompany: true,
    children: [
      {
        id: 'addPurchase',
        title: 'Tạo thu mua',
        type: 'item',
        icon: <Icon.Plus size={12} />,
        mustViewCompany: true,
        navLink: ROUTES.PURCHASE.ADD,
        api: [{ method: API_METHOD.POST, route: API_ROUTE.PURCHASE.BOOKING }],
        parentOf: [
          {
            id: 'infoAddPurchase',
            title: 'Thông tin thu mua',
            navLink: ROUTES.PURCHASE.ADD_OVERVIEW(':id'),
            mustViewCompany: true,
            api: [{ method: API_METHOD.GET, route: API_ROUTE.PURCHASE.HISTORY_DETAIL }],
          },
        ],
      },
      {
        id: 'historyPurchase',
        title: 'Lịch sử thu mua',
        type: 'item',
        icon: <Icon.List size={12} />,
        mustViewCompany: true,
        navLink: ROUTES.PURCHASE.HISTORY,
        api: [
          { method: API_METHOD.GET, route: API_ROUTE.PURCHASE.HISTORY },
          { method: API_METHOD.DELETE, route: API_ROUTE.PURCHASE.DELETE_HISTORY_DETAIL(':id') },
        ],
        parentOf: [
          {
            id: 'detailHistoryPurchase',
            title: 'Chi tiết lịch sử thu mua',
            navLink: ROUTES.PURCHASE.HISTORY_DETAIL(':id'),
            mustViewCompany: true,
            api: [{ method: API_METHOD.GET, route: API_ROUTE.PURCHASE.HISTORY_DETAIL(':id') }],
          },
          {
            id: 'editHistoryPurchase',
            title: 'Sửa lịch sử thu mua',
            navLink: ROUTES.PURCHASE.HISTORY_EDIT(':id'),
            mustViewCompany: true,
            api: [{ method: API_METHOD.PUT, route: API_ROUTE.PURCHASE.EDIT_HISTORY(':id') }],
          },
        ],
      },
      {
        id: 'historySyncPurchase',
        title: 'Lịch sử đồng bộ',
        type: 'item',
        icon: <Icon.RefreshCw size={12} />,
        mustViewCompany: true,
        navLink: ROUTES.PURCHASE.SYNC_HISTORY,
        api: [
          { method: API_METHOD.GET, route: API_ROUTE.PURCHASE.SYNC_HISTORY },
          { method: API_METHOD.POST, route: API_ROUTE.PURCHASE.SYNC },
        ],
        parentOf: [
          {
            id: 'detailHistorySyncPurchase',
            title: 'Chi tiết lịch sử đồng bộ',
            navLink: ROUTES.PURCHASE.SYNC_HISTORY_DETAIL(':id'),
            mustViewCompany: true,
            api: [{ method: API_METHOD.GET, route: API_ROUTE.PURCHASE.SYNC_HISTORY_DETAIL(':id') }],
          },
        ],
      },
    ],
  },
  {
    id: 'farmUser',
    title: 'Nông hộ',
    type: 'item',
    icon: <Icon.Flag size={20} />,
    navLink: ROUTES.FARM_USER.LIST,
    mustViewCompany: true,
    api: [
      { method: API_METHOD.GET, route: API_ROUTE.FARM_USER.LIST },
      { method: API_METHOD.GET, route: API_ROUTE.FARM_USER.EXPORT },
    ],
    parentOf: [
      {
        id: 'farmUserDetail',
        title: 'Xem nông hộ',
        navLink: ROUTES.FARM_USER.DETAIL(':id'),
        mustViewCompany: true,
        api: [{ method: API_METHOD.GET, route: API_ROUTE.FARM_USER.DETAIL(':id') }],
      },
    ],
  },
  {
    id: 'farm',
    title: 'Điểm canh tác',
    type: 'item',
    icon: <Icon.Square size={20} />,
    navLink: ROUTES.FARM.LIST,
    mustViewCompany: true,
    api: [
      { method: API_METHOD.GET, route: API_ROUTE.FARM.LIST },
      { method: API_METHOD.DELETE, route: API_ROUTE.FARM.DELETE(':id') },
    ],
    parentOf: [
      {
        id: 'farmCreate',
        title: 'Tạo điểm canh tác',
        navLink: ROUTES.FARM.CREATE,
        mustViewCompany: true,
        api: [{ method: API_METHOD.POST, route: API_ROUTE.FARM.CREATE }],
      },
      {
        id: 'farmImport',
        title: 'Nhập điểm canh tác',
        navLink: ROUTES.FARM.IMPORT,
        mustViewCompany: true,
        api: [{ method: API_METHOD.POST, route: API_ROUTE.FARM.IMPORT }],
      },
      {
        id: 'farmDetail',
        title: 'Xem điểm canh tác',
        navLink: ROUTES.FARM.DETAIL(':id'),
        mustViewCompany: true,
        api: [{ method: API_METHOD.GET, route: API_ROUTE.FARM.DETAIL(':id') }],
      },
      {
        id: 'farmEdit',
        title: 'Sửa điểm canh tác',
        navLink: ROUTES.FARM.EDIT(':id'),
        mustViewCompany: true,
        api: [{ method: API_METHOD.PUT, route: API_ROUTE.FARM.EDIT(':id') }],
      },
    ],
  },
  {
    id: 'geological',
    title: 'Dữ liệu thực địa',
    type: 'item',
    icon: <Icon.Map size={20} />,
    navLink: ROUTES.GEOLOGICAL.LIST,
    mustViewCompany: true,
    api: [
      { method: API_METHOD.GET, route: API_ROUTE.GEOLOGICAL.LIST },
      { method: API_METHOD.GET, route: API_ROUTE.GEOLOGICAL.ALL_AUTHORS },
      { method: API_METHOD.DELETE, route: API_ROUTE.GEOLOGICAL.DELETE(':id') },
    ],
    parentOf: [
      {
        id: 'geologicalImport',
        title: 'Nhập dữ liệu thực địa',
        navLink: ROUTES.GEOLOGICAL.IMPORT,
        mustViewCompany: true,
        api: [{ method: API_METHOD.POST, route: API_ROUTE.GEOLOGICAL.IMPORT }],
      },
      {
        id: 'geologicalDetail',
        title: 'Xem dữ liệu thực địa',
        navLink: ROUTES.GEOLOGICAL.DETAIL(':id'),
        mustViewCompany: true,
        api: [{ method: API_METHOD.GET, route: API_ROUTE.GEOLOGICAL.DETAIL(':id') }],
      },
      {
        id: 'geologicalEdit',
        title: 'Sửa dữ liệu thực địa',
        navLink: ROUTES.GEOLOGICAL.EDIT(':id'),
        mustViewCompany: true,
        api: [{ method: API_METHOD.PUT, route: API_ROUTE.GEOLOGICAL.EDIT(':id') }],
      },
    ],
  },
  // {
  //   id: 'product',
  //   title: 'Sản phẩm',
  //   type: 'item',
  //   icon: <Icon.ShoppingCart size={20} />,
  //   navLink: ROUTES.PRODUCT.LIST,
  //   api: [
  //     { method: API_METHOD.GET, route: API_ROUTE.PRODUCT.LIST },
  //     { method: API_METHOD.DELETE, route: API_ROUTE.PRODUCT.DELETE(':id') },
  //     { method: API_METHOD.POST, route: API_ROUTE.PRODUCT.COPY(':id') },
  //   ],
  //   parentOf: [
  //     {
  //       id: 'productCreate',
  //       title: 'Tạo sản phẩm',
  //       navLink: ROUTES.PRODUCT.CREATE,
  //       api: [{ method: API_METHOD.POST, route: API_ROUTE.PRODUCT.CREATE }],
  //     },
  //     {
  //       id: 'productDetail',
  //       title: 'Xem sản phẩm',
  //       navLink: ROUTES.PRODUCT.DETAIL(':id'),
  //       api: [{ method: API_METHOD.GET, route: API_ROUTE.PRODUCT.DETAIL(':id') }],
  //     },
  //     {
  //       id: 'productEdit',
  //       title: 'Sửa sản phẩm',
  //       navLink: ROUTES.PRODUCT.EDIT(':id'),
  //       api: [{ method: API_METHOD.PUT, route: API_ROUTE.PRODUCT.EDIT(':id') }],
  //     },
  //   ],
  // },
  // {
  //   id: 'qrCode',
  //   title: 'QrCode',
  //   type: 'item',
  //   icon: <Icon.Code size={20} />,
  //   navLink: ROUTES.QR_CODE.LIST,
  //   api: [
  //     { method: API_METHOD.GET, route: API_ROUTE.QR_CODE.LIST },
  //     { method: API_METHOD.DELETE, route: API_ROUTE.QR_CODE.DELETE(':id') },
  //   ],
  //   parentOf: [
  //     {
  //       id: 'qrCodeCreate',
  //       title: 'Tạo QrCode',
  //       navLink: ROUTES.QR_CODE.CREATE,
  //       api: [{ method: API_METHOD.POST, route: API_ROUTE.QR_CODE.CREATE }],
  //     },
  //     {
  //       id: 'qrCodeDetail',
  //       title: 'Xem QrCode',
  //       navLink: ROUTES.QR_CODE.DETAIL(':id'),
  //       api: [{ method: API_METHOD.GET, route: API_ROUTE.QR_CODE.DETAIL(':id') }],
  //     },
  //     {
  //       id: 'qrCodeEdit',
  //       title: 'Sửa QrCode',
  //       navLink: ROUTES.QR_CODE.EDIT(':id'),
  //       api: [{ method: API_METHOD.PUT, route: API_ROUTE.QR_CODE.EDIT(':id') }],
  //     },
  //   ],
  // },
  {
    id: 'user',
    title: 'User',
    type: 'item',
    onlyAdmin: true,
    icon: <Icon.Users size={20} />,
    navLink: ROUTES.USER.LIST,
    parentOf: [
      {
        id: 'userCreate',
        title: 'Tạo user',
        navLink: ROUTES.USER.CREATE,
      },
      {
        id: 'userDetail',
        title: 'Xem user',
        navLink: ROUTES.USER.DETAIL(':id'),
      },
      {
        id: 'userEdit',
        title: 'Sửa user',
        navLink: ROUTES.USER.EDIT(':id'),
      },
    ],
  },
  {
    id: 'setting',
    title: 'Cài đặt',
    type: 'collapse',
    onlyAdmin: true,
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: 'settingRole',
        title: 'Nhóm quyền',
        type: 'item',
        icon: <Icon.User size={12} />,
        navLink: ROUTES.SETTING.ROLE.LIST,
        parentOf: [
          {
            id: 'settingRoleCreate',
            title: 'Tạo nhóm quyền',
            navLink: ROUTES.SETTING.ROLE.CREATE,
          },
          {
            id: 'settingRoleDetail',
            title: 'Xem nhóm quyền',
            navLink: ROUTES.SETTING.ROLE.DETAIL(':id'),
          },
          {
            id: 'settingRoleEdit',
            title: 'Sửa nhóm quyền',
            navLink: ROUTES.SETTING.ROLE.EDIT(':id'),
          },
        ],
      },
      {
        id: 'settingPermission',
        title: 'Quyền hạn',
        type: 'item',
        icon: <Icon.XCircle size={12} />,
        navLink: ROUTES.SETTING.PERMISSION.LIST,
        parentOf: [
          {
            id: 'settingPermissionCreate',
            title: 'Tạo quyền hạn',
            navLink: ROUTES.SETTING.PERMISSION.CREATE,
          },
          {
            id: 'settingPermissionDetail',
            title: 'Xem quyền hạn',
            navLink: ROUTES.SETTING.PERMISSION.DETAIL(':id'),
          },
          {
            id: 'settingPermissionEdit',
            title: 'Sửa quyền hạn',
            navLink: ROUTES.SETTING.PERMISSION.EDIT(':id'),
          },
        ],
      },
      {
        id: 'settingTransactionType',
        title: 'Loại giao dịch',
        type: 'item',
        icon: <Icon.XCircle size={12} />,
        navLink: ROUTES.SETTING.TRANSACTION_TYPE.LIST,
        parentOf: [
          {
            id: 'settingTransactionTypeCreate',
            title: 'Tạo loại giao dịch',
            navLink: ROUTES.SETTING.TRANSACTION_TYPE.CREATE,
          },
          {
            id: 'settingTransactionTypeDetail',
            title: 'Xem loại giao dịch',
            navLink: ROUTES.SETTING.TRANSACTION_TYPE.DETAIL(':id'),
          },
          {
            id: 'settingTransactionTypeEdit',
            title: 'Sửa loại giao dịch',
            navLink: ROUTES.SETTING.TRANSACTION_TYPE.EDIT(':id'),
          },
        ],
      },
    ],
  },
];

export default navigationConfig;
