import { createSlice } from '@reduxjs/toolkit';
import themeConfig from '../../configs/themeConfig';

export const customizerSlice = createSlice({
  name: 'customizer',
  initialState: themeConfig,
  reducers: {
    collapseSidebar: (state, action) => {
      state.sidebarCollapsed = action.payload;
    },
  },
});

export const { collapseSidebar } = customizerSlice.actions;

export default customizerSlice.reducer;
