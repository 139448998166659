import axios from 'axios';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers,
});

export default instance;
