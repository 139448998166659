import React from 'react';
import { UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import UserDropdown from './UserDropdown';
import userImg from '@src/assets/img/icons/user.jpeg';
import { history } from '@src/history';
import { ROUTES } from '@src/configs/constants';

const NavbarUser = ({ user, logout }) => {
  const goToEditProfile = () => {
    history.push(ROUTES.PROFILE);
  };
  const handleLogout = () => {
    logout().then(() => {
      history.push(ROUTES.LOGIN);
    });
  };

  return (
    <ul className="nav navbar-nav navbar-nav-user">
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            {!!user && <span className="user-name text-bold-600">{user.fullName}</span>}
          </div>
          <span data-tour="user">
            <img src={userImg} className="round" height="40" width="40" alt="avatar" />
          </span>
        </DropdownToggle>
        <UserDropdown goToEditProfile={goToEditProfile} handleLogout={handleLogout} />
      </UncontrolledDropdown>
    </ul>
  );
};

export default NavbarUser;
