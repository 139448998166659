import { combineReducers } from 'redux';
import customizer from './customizer';
import auth from './auth';
import setting from './setting';

const rootReducer = combineReducers({
  customizer,
  auth,
  setting,
});

export default rootReducer;
