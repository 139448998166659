import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import axios from './services/axios';
import { Layout } from './utility/context/Layout';
import { store } from './redux/storeConfig/store';
import { setRequestInterceptor, setResponseInterceptor } from './utility/interceptor';
import Spinner from './components/@vuexy/spinner/Fallback-spinner';
import i18n from './services/i18n';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/plugins/extensions/toastr.scss';

const LazyApp = lazy(() => import('./App'));
setRequestInterceptor(axios);
setResponseInterceptor(axios, store);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <LazyApp />
          <ToastContainer />
        </Layout>
      </Suspense>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root'),
);
