import React from 'react';
import VerticalLayout from '../../layouts/VerticalLayout';
import FullLayout from '../../layouts/FullpageLayout';
import FrontLayout from '../../layouts/FrontLayout';

const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
  frontLayout: FrontLayout,
};

const ContextLayout = React.createContext();

class Layout extends React.PureComponent {
  state = {
    width: window.innerWidth,
  };

  updateWidth = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  handleWindowResize = () => {
    this.updateWidth();
  };

  componentDidMount = () => {
    if (window !== 'undefined') {
      window.addEventListener('resize', this.handleWindowResize);
    }
  };

  render() {
    const { children } = this.props;
    return (
      <ContextLayout.Provider
        value={{
          state: this.state,
          fullLayout: layouts['full'],
          frontLayout: layouts['frontLayout'],
          VerticalLayout: layouts['vertical'],
        }}>
        {children}
      </ContextLayout.Provider>
    );
  }
}

export { Layout, ContextLayout };
