import { LANG, USER } from '../configs/constants';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import isDeepEqual from 'lodash/isEqual';
import fileDownload from 'js-file-download';

export const dateTimeFormat = memoizeOne((date) => {
  if (isNotEmpty(date)) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  return null;
});

export const dateInfo = memoizeOne((value) => {
  if (isNotEmpty(value)) {
    const date = moment(value);

    return {
      date: date.date(),
      month: date.month(),
      year: date.year(),
    };
  }

  return null;
});

export const dateFormat = memoizeOne((date) => {
  if (isNotEmpty(date)) {
    return moment(date).format('YYYY-MM-DD');
  }
  return null;
});

export const downloadFileBlog = (blog, filename) => {
  fileDownload(blog, filename);
};

export const isNotEmpty = memoizeOne((value) => {
  return typeof value !== 'undefined' && value !== null && value !== '';
});

export const hasPrice = memoizeOne((price) => {
  if (price === 0 || price === '0') {
    return true;
  }

  return !!price;
});

export const getCountryOpt = memoizeOne((lang) => {
  let label;
  let code;
  switch (lang) {
    case LANG.EN:
      label = 'English';
      code = 'us';
      break;
    default:
      label = 'Tiếng Việt';
      code = 'vn';
      break;
  }
  return {
    lang,
    code,
    label,
  };
});

export const getQueryRoute = (location, name, defaultValue = null) => {
  try {
    const { search } = location;
    const url = new URLSearchParams(search);

    return url.get(name) || defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const serializeUrl = function (dataUrl) {
  const str = [];
  for (const p in dataUrl) {
    if (dataUrl.hasOwnProperty(p) && !!dataUrl[p]) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(dataUrl[p]));
    }
  }
  return str.join('&');
};

export const logger = (...any) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log(...any);
  }
};

export const isVideo = (fileType) => {
  return !!fileType.match('video.*');
};

export const isImage = (fileType) => {
  return !!fileType.match('image.*');
};

export const isArray = (value) => Array.isArray(value);

export const isBlob = (value) =>
  value && typeof value.size === 'number' && typeof value.type === 'string' && typeof value.slice === 'function';

export const isFile = (value) =>
  isBlob(value) &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' || typeof value.lastModified === 'number');

export const urlToFile = (fileUrl, fileName) => {
  return new Promise((resolve) => {
    const request = new XMLHttpRequest();
    request.open('GET', fileUrl, true);
    request.responseType = 'blob';
    request.onload = function () {
      const reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = function (e) {
        const fileData = dataURLtoFile(e.target.result, fileName);
        fileData.preview = URL.createObjectURL(fileData);
        resolve(fileData);
      };
    };
    request.send();
  });
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

export const imageBase64ByteSize = (base64String) => {
  let padding;

  if (base64String.endsWith('==')) {
    padding = 2;
  } else if (base64String.endsWith('=')) {
    padding = 1;
  } else {
    padding = 0;
  }

  const base64StringLength = base64String.length;

  return (3 * base64StringLength) / 4 - padding;
};

export const dataURLtoFile = (dataUrl, fileName) => {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

export const getRouteApp = (routeConfigs, getOptionValue) => {
  let routeOptions = [];

  routeConfigs.forEach((routeConfig) => {
    if (!routeConfig.onlyAdmin) {
      if (!routeConfig.children) {
        routeOptions = [...routeOptions, getOptionValue(routeConfig)];
      }

      if (!!routeConfig.parentOf) {
        routeOptions = [...routeOptions, ...getRouteApp(routeConfig.parentOf, getOptionValue)];
      }

      if (!!routeConfig.children) {
        routeOptions = [...routeOptions, ...getRouteApp(routeConfig.children, getOptionValue)];
      }
    }
  });

  return routeOptions;
};

export const routeParentOf = (item, routeItemState) => {
  return item.parentOf && item.parentOf.some((parent) => parent.navLink === routeItemState);
};

export const roleOf = memoizeOne((user, roles) => {
  return !!user && roles.includes(user.role.slug);
});

export const renderWith = memoizeOne((value, defaultValue = '-') => {
  return isNotEmpty(value) ? value : defaultValue;
});

export const allowAccess = memoizeOne((user, menuItem) => {
  // AUTO ALLOW IF THAT MENU IS PUBLIC
  if (menuItem.public) {
    return true;
  }

  if (!!user) {
    let exceptsAccess = true;
    let permissionsAccess = false;
    const roleSlug = !!user.role ? user.role.slug : null;

    if (!!menuItem.excepts) {
      exceptsAccess = !menuItem.excepts.includes(roleSlug);
    } else if (!!menuItem.permissions) {
      permissionsAccess = menuItem.permissions.includes(roleSlug);
    } else if (roleOf(user, [USER.ROLES.ADMIN])) {
      // Only allow menu item that have company
      if (menuItem.mustViewCompany) {
        return !!user.company;
      }

      // AUTO ALLOW IF ROLE OF USER IS ADMIN
      return true;
    }

    if (menuItem.type === 'collapse') {
      return menuItem.children.some((childMenuItem) => allowAccess(user, childMenuItem));
    }

    return permissionsAccess || (exceptsAccess && !!user.accessRoutes.find((r) => r.webId === menuItem.id));
  }

  return false;
}, isDeepEqual);

export const redirectByPath = (user, item, activePath) => {
  let routes = [];
  if (item.type === 'item') {
    if (!!item.parentOf) {
      const foundChildItem = item.parentOf.find((p) => p.navLink === activePath);
      if (!!foundChildItem) {
        return !allowAccess(user, foundChildItem);
      }
    }
    return item.navLink === activePath && !allowAccess(user, item);
  } else if (item.type === 'collapse') {
    routes = getRouteApp(item.children || [], (routeConfig) => ({
      id: routeConfig.id,
      permissions: routeConfig.permissions,
      excepts: routeConfig.excepts,
      navLink: routeConfig.navLink,
    }));
  }

  const foundRoute = routes.find((r) => r.navLink === activePath);

  return !!foundRoute && !allowAccess(user, foundRoute);
};

export const serializeFormData = (values) => {
  const formData = new FormData();
  if (!!values) {
    Object.entries(values).forEach(([key, value]) => {
      if (isArray(value)) {
        let isFileNested = false;
        value.forEach((v) => {
          if (isFile(v)) {
            isFileNested = true;
            formData.append(key, v);
          }
        });

        if (!isFileNested) {
          formData.append(key, JSON.stringify(value));
        }
      } else {
        formData.append(key, value || '');
      }
    });
  }

  return formData;
};
