import { getStorage, setStorage } from '../services/asyncStorage';
import { DEFAULT_LANG, STORAGE } from '../configs/constants';
import i18n from './../services/i18n';

const setting = {
  async getLang() {
    const lang = getStorage(STORAGE.APP_LANG, DEFAULT_LANG);
    await i18n.changeLanguage(lang);
    return lang;
  },
  async changeLanguage(lang) {
    setStorage(STORAGE.APP_LANG, lang);
    await i18n.changeLanguage(lang);

    return lang;
  },
};

export default setting;
