import React, { memo } from 'react';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import * as Icon from 'react-feather';

const UserDropdown = ({ goToEditProfile, handleLogout }) => {
  return (
    <DropdownMenu right>
      <DropdownItem onClick={goToEditProfile} className="w-100">
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Thông tin cá nhân</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem onClick={handleLogout} className="w-100">
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Đăng xuất</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

export default memo(UserDropdown);
