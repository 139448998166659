export const STORAGE = {
  APP_AUTHENTICATE_TOKEN: '@app:authenticate_token',
  APP_LANG: '@app:lang',
  APP_VIEW_COMPANY: '@app:view_company',
};

export const LANG = {
  VI: 'vi',
  EN: 'en',
};

export const DEFAULT_LANG = LANG.VI;

export const NEWS_TYPE = {
  ALL: '0',
  PRIVATE: '1',
};

export const USER = {
  ROLES: {
    ADMIN: 'admin',
    COMPANY_ADMIN: 'quan-ly-cong-ty',
    KE_TOAN: 'ke-toan',
    THU_MUA: 'thu-mua',
    GIAM_SAT_THUC_DIA: 'giam-sat-thuc-dia',
    KHAO_SAT_THUC_DIA: 'khao-sat-thuc-dia',
    HO_NONG_DAN: 'ho-nong-dan',
  },
  STATUS: {
    LOCK: '1',
    ENABLE: '0',
  },
};

export const PAGINATION = {
  PER_PAGE: 10,
};

export const API_METHOD = {
  ALL: '*',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const TYPE_FIELD_LIST = [
  { value: 'text', label: 'Text' },
  { value: 'number', label: 'Number' },
  { value: 'checkbox', label: 'Checkbox' },
  { value: 'radioButton', label: 'Radio Button' },
  { value: 'images', label: 'Images' },
  { value: 'farm', label: 'Farm' },
];

// TODO: REMEMBER ADD FIRST SPLASH (/)
export const API_ROUTE = {
  AUTH: {
    LOGIN: '/api/auth/login',
    VIEW_AS_COMPANY: '/api/auth/view-as-company',
    PROFILE: '/api/auth/profile',
    UPDATE_GENERAL: '/api/auth/general',
    CHANGE_PASSWORD: '/api/auth/change-password',
  },
  ANALYTIC: {
    USER: '/api/analytic/user',
    FARM: '/api/analytic/farm',
    YESTERDAY_PURCHASE: '/api/analytic/yesterday/purchase',
    YESTERDAY_PURCHASE_PRICE: '/api/analytic/yesterday/purchase-price',
    TODAY_PURCHASE: '/api/analytic/today/purchase',
    TODAY_PURCHASE_PRICE: '/api/analytic/today/purchase-price',
  },
  COMPANY: {
    MANAGE: {
      ALL: '/api/company/manage/*',
      LIST_ROLE: `/api/company/manage/roles`,
      EXPORT: `/api/company/manage/roles`,
      LIST: '/api/company/manage',
      CREATE: `/api/company/manage/create`,
      EDIT: (id) => `/api/company/manage/${id}`,
      DELETE: (id) => `/api/company/manage/${id}`,
      DETAIL: (id) => `/api/company/manage/${id}`,

      USER: (companyId) => ({
        LIST: `/api/company/manage/${companyId}/user`,
        EXPORT: `/api/company/manage/${companyId}/user/export`,
        MULTI_DELETE: `/api/company/manage/${companyId}/user/multi-delete`,
        CREATE: `/api/company/manage/${companyId}/user`,
        EDIT: (id) => `/api/company/manage/${companyId}/user/${id}`,
        LOCK: (id) => `/api/company/manage/${companyId}/user/${id}`,
        DETAIL: (id) => `/api/company/manage/${companyId}/user/${id}`,
      }),
    },
  },
  OWNER: {
    HO_NONG_DAN: '/api/company/own/ho-nong-dan',
    LIST_TEA: '/api/company/own/tea',
    FARM: '/api/company/own/farm',
  },
  NEWS: {
    LIST: '/api/news',
    FULL_LIST: '/api/news/list',
    CREATE: `/api/news/create`,
    EDIT: (id) => `/api/news/${id}`,
    DELETE: (id) => `/api/news/${id}`,
    DETAIL: (id) => `/api/news/${id}`,
  },
  TEA: {
    MANAGE: {
      LIST: '/api/tea',
      CREATE: `/api/tea/create`,
      EDIT: (id) => `/api/tea/${id}`,
      DELETE: (id) => `/api/tea/${id}`,
      DETAIL: (id) => `/api/tea/${id}`,
    },
    VARIANT: {
      LIST: '/api/tea-variant',
      CREATE: `/api/tea-variant/create`,
      EDIT: (id) => `/api/tea-variant/${id}`,
      DELETE: (id) => `/api/tea-variant/${id}`,
      DETAIL: (id) => `/api/tea-variant/${id}`,
    },
  },
  PURCHASE: {
    BOOKING: `/api/purchase/booking`,
    HISTORY: '/api/purchase/booking/history',
    HISTORY_DETAIL: (id) => `/api/purchase/booking/history/${id}`,
    DELETE_HISTORY_DETAIL: (id) => `/api/purchase/booking/history/${id}`,
    EDIT_HISTORY: (id) => `/api/purchase/booking/history/${id}`,
    SYNC: '/api/purchase/sync',
    SYNC_HISTORY: '/api/purchase/sync/history',
    SYNC_HISTORY_DETAIL: (id) => `/api/purchase/sync/history/${id}`,
  },
  FARM_USER: {
    LIST: '/api/farm-user',
    DETAIL: (id) => `/api/farm-user/${id}`,
    EXPORT: '/api/farm-user/export',
  },
  FARM: {
    LIST: '/api/farm',
    CREATE: `/api/farm/create`,
    IMPORT: `/api/farm/import`,
    EDIT: (id) => `/api/farm/${id}`,
    DELETE: (id) => `/api/farm/${id}`,
    DETAIL: (id) => `/api/farm/${id}`,
  },
  GEOLOGICAL: {
    LIST: '/api/geological',
    ALL_AUTHORS: '/api/geological/authors',
    IMPORT: `/api/geological/import`,
    EDIT: (id) => `/api/geological/${id}`,
    DELETE: (id) => `/api/geological/${id}`,
    DETAIL: (id) => `/api/geological/${id}`,
  },
  PRODUCT: {
    LIST: '/api/product',
    CREATE: `/api/product/create`,
    EDIT: (id) => `/api/product/${id}`,
    DELETE: (id) => `/api/product/${id}`,
    COPY: (id) => `/api/product/copy/${id}`,
    VIEW: (id) => `/api/product/view/${id}`,
    DETAIL: (id) => `/api/product/${id}`,
  },
  QR_CODE: {
    LIST: '/api/qrcode',
    CREATE: `/api/qrcode/create`,
    EDIT: (id) => `/api/qrcode/${id}`,
    DELETE: (id) => `/api/qrcode/${id}`,
    DETAIL: (id) => `/api/qrcode/${id}`,
  },
  USER: {
    LIST: '/api/user',
    EXPORT: '/api/user/export',
    CREATE: `/api/user/create`,
    MULTI_DELETE: `/api/user/multi-delete`,
    EDIT: (id) => `/api/user/${id}`,
    LOCK: (id) => `/api/user/${id}`,
    DETAIL: (id) => `/api/user/${id}`,
  },
  ROLE: {
    LIST: '/api/role',
    CREATE: `/api/role/create`,
    EDIT: (id) => `/api/role/${id}`,
    DELETE: (id) => `/api/role/${id}`,
    DETAIL: (id) => `/api/role/${id}`,
  },
  PERMISSION: {
    LIST: '/api/permission',
    CREATE: `/api/permission/create`,
    EDIT: (id) => `/api/permission/${id}`,
    DELETE: (id) => `/api/permission/${id}`,
    DETAIL: (id) => `/api/permission/${id}`,
  },
  TRANSACTION_TYPE: {
    LIST: '/api/transaction-type',
    CREATE: `/api/transaction-type/create`,
    EDIT: (id) => `/api/transaction-type/${id}`,
    // DELETE: (id) => `/api/transaction-type/${id}`,
    DETAIL: (id) => `/api/transaction-type/${id}`,
  },
  TRANSACTION_HISTORY: {
    LIST: '/api/transaction-history',
    DETAIL: (id) => `/api/transaction-history/${id}`,
  },
};

export const ROUTES = {
  DASHBOARD: '/',
  LOGIN: '/login',
  PROFILE: '/profile',
  NOT_AUTH: '/error/not-authorized',
  PAGE_404: '/error/404',
  PAGE_500: '/error/500',
  COMPANY: {
    MANAGE: {
      HOME: '/company/manage',
      CREATE: '/company/manage/create',
      EDIT: (id) => `/company/manage/edit/${id}`,
      DETAIL: (id) => `/company/manage/${id}`,
      USER: (companyId) => ({
        LIST: `/company/manage/${companyId}/user`,
        CREATE: `/company/manage/${companyId}/user`,
        EDIT: (id) => `/company/manage/${companyId}/user/edit/${id}`,
        DETAIL: (id) => `/company/manage/${companyId}/user/${id}`,
      }),
    },
  },
  NEWS: {
    LIST: '/news',
    CREATE: '/news/create',
    EDIT: (id) => `/news/edit/${id}`,
    DETAIL: (id) => `/news/${id}`,
  },
  TRANSACTION_HISTORY: {
    LIST: '/transaction-history',
    DETAIL: (id) => `/transaction-history/${id}`,
  },
  TEA: {
    MANAGE: {
      LIST: '/tea/manage',
      CREATE: '/tea/manage/create',
      EDIT: (id) => `/tea/manage/edit/${id}`,
    },
    VARIANT: {
      LIST: '/tea/variant',
      CREATE: '/tea/variant/create',
      EDIT: (id) => `/tea/variant/edit/${id}`,
      DETAIL: (id) => `/tea/variant/${id}`,
    },
  },
  PURCHASE: {
    ADD: '/purchase/add',
    ADD_OVERVIEW: (id) => `/purchase/add/${id}`,
    HISTORY: '/purchase/history',
    HISTORY_EDIT: (id) => `/purchase/history/edit/${id}`,
    HISTORY_DETAIL: (id) => `/purchase/history/${id}`,
    SYNC_HISTORY: '/purchase/sync',
    SYNC_HISTORY_DETAIL: (id) => `/purchase/sync/${id}`,
  },
  FARM_USER: {
    LIST: '/farm-user',
    DETAIL: (id) => `/farm-user/${id}`,
  },
  FARM: {
    LIST: '/farm',
    CREATE: '/farm/create',
    IMPORT: '/farm/import',
    EDIT: (id) => `/farm/edit/${id}`,
    DETAIL: (id) => `/farm/${id}`,
  },
  GEOLOGICAL: {
    LIST: '/geological',
    IMPORT: '/geological/import',
    EDIT: (id) => `/geological/edit/${id}`,
    DETAIL: (id) => `/geological/${id}`,
  },
  PRODUCT: {
    LIST: '/product',
    CREATE: '/product/create',
    VIEW: (id) => `/product/view/${id}`,
    EDIT: (id) => `/product/edit/${id}`,
    DETAIL: (id) => `/product/${id}`,
  },
  QR_CODE: {
    LIST: '/qrcode',
    CREATE: '/qrcode/create',
    EDIT: (id) => `/qrcode/edit/${id}`,
    DETAIL: (id) => `/qrcode/${id}`,
  },
  USER: {
    LIST: '/user',
    CREATE: '/user/create',
    EDIT: (id) => `/user/edit/${id}`,
    DETAIL: (id) => `/user/${id}`,
  },
  SETTING: {
    ROLE: {
      LIST: '/settings/role',
      CREATE: '/settings/role/create',
      EDIT: (id) => `/settings/role/edit/${id}`,
      DETAIL: (id) => `/settings/role/${id}`,
    },
    PERMISSION: {
      LIST: '/settings/permissions',
      CREATE: '/settings/permissions/create',
      EDIT: (id) => `/settings/permissions/edit/${id}`,
      DETAIL: (id) => `/settings/permissions/${id}`,
    },
    TRANSACTION_TYPE: {
      LIST: '/settings/transaction-types',
      CREATE: '/settings/transaction-types/create',
      EDIT: (id) => `/settings/transaction-types/edit/${id}`,
      DETAIL: (id) => `/settings/transaction-types/${id}`,
    },
  },
};
