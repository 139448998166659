import { configureStore } from '@reduxjs/toolkit';
import createDebounce from 'redux-debounced';
import rootReducer from '../reducers/rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createDebounce()),
});

export { store };
