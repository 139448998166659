export const hasWindow = () => {
  return typeof window !== 'undefined';
};

/**
 * Set data to storage
 *
 * @param {String} key
 * @param {any} value
 */
export const setStorage = (key, value) => {
  if (hasWindow()) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

/**
 * Remove data from storage
 */
export const clearStorage = () => {
  if (hasWindow()) {
    window.localStorage.clear();
  }
};

/**
 * Remove all data from storage
 *
 * @param {String} key
 */
export const removeStorage = (key) => {
  if (hasWindow()) {
    window.localStorage.removeItem(key);
  }
};

/**
 * Get data from storage
 *
 * @param {String} key
 * @param {any} defaultValue
 */
export const getStorage = (key, defaultValue = null) => {
  if (hasWindow()) {
    const value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value) || defaultValue;
    }
  }

  return defaultValue;
};
