import React from 'react';
import classnames from 'classnames';

const FrontLayout = ({ children }) => {
  return (
    <div className={classnames('full-layout wrapper blank-page dark-layout')}>
      <div className="app-content">{children}</div>
    </div>
  );
};

export default FrontLayout;
