import React, { memo } from 'react';
import { Button, Navbar, NavLink, NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import { X, Menu } from 'react-feather';
import classnames from 'classnames';
import NavbarUser from './NavbarUser';
import { actLogout, actUnViewCompany } from '@src/redux/reducers/auth';
import { roleOf } from '@src/utility/helper';
import { ROUTES, USER } from '@src/configs/constants';
import { history } from '@src/history';

const ThemeNavbar = ({
  sidebarVisibility,
  navbarColor,
  navbarType,
  horizontal,
  scrolling,
  logout,
  actUnViewCompany,
  user,
}) => {
  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark'];
  const navbarTypes = ['floating', 'static', 'sticky', 'hidden'];
  const unViewCompany = () => {
    history.push(ROUTES.DASHBOARD);
    actUnViewCompany();
  };
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames('header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow', {
          'navbar-light': navbarColor === 'default' || !colorsArr.includes(navbarColor),
          'navbar-dark': colorsArr.includes(navbarColor),
          'bg-primary': navbarColor === 'primary' && navbarType !== 'static',
          'bg-danger': navbarColor === 'danger' && navbarType !== 'static',
          'bg-success': navbarColor === 'success' && navbarType !== 'static',
          'bg-info': navbarColor === 'info' && navbarType !== 'static',
          'bg-warning': navbarColor === 'warning' && navbarType !== 'static',
          'bg-dark': navbarColor === 'dark' && navbarType !== 'static',
          'd-none': navbarType === 'hidden' && !horizontal,
          'floating-nav':
            (navbarType === 'floating' && !horizontal) || (!navbarTypes.includes(navbarType) && !horizontal),
          'navbar-static-top': navbarType === 'static' && !horizontal,
          'fixed-top': navbarType === 'sticky' || horizontal,
          scrolling: horizontal && scrolling,
        })}>
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div className="navbar-collapse d-flex align-items-center" id="navbar-mobile">
              <div className="bookmark-wrapper">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className="navbar-nav d-xl-none">
                    <NavItem className="mobile-menu mr-auto">
                      <NavLink className="nav-menu-main menu-toggle hidden-xs is-active" onClick={sidebarVisibility}>
                        <Menu />
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
              </div>
              <div className="flex-grow-1">
                {roleOf(user, [USER.ROLES.ADMIN]) && !!user.company && (
                  <React.Fragment>
                    <span className="align-middle">
                      Đang xem{' '}
                      <b>
                        {user.company.companyCode} - {user.company.name}
                      </b>
                    </span>
                    <Button.Ripple
                      className="ml-1 rounded-circle btn-icon"
                      color="flat-danger"
                      type="button"
                      onClick={unViewCompany}>
                      <X className="collapse-icon" size={20} />
                    </Button.Ripple>
                  </React.Fragment>
                )}
              </div>
              <NavbarUser logout={logout} user={user} />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapState = (state) => ({
  user: state.auth.user,
});

const mapDispatch = (dispatch) => ({
  logout: () => dispatch(actLogout()),
  actUnViewCompany: () => dispatch(actUnViewCompany()),
});

export default connect(mapState, mapDispatch)(memo(ThemeNavbar));
