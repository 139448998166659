import { getStorage } from '../services/asyncStorage';
import { ROUTES, STORAGE } from '../configs/constants';
import { logger } from './helper';
import { actLogout } from '../redux/reducers/auth';
import { history } from '../history';

export const setRequestInterceptor = (axios) => {
  axios.interceptors.request.use(
    async (config) => {
      const token = await getStorage(STORAGE.APP_AUTHENTICATE_TOKEN);
      const companyId = await getStorage(STORAGE.APP_VIEW_COMPANY);
      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
        if (!!companyId) {
          config.headers['company-id'] = companyId;
        }
        config.headers.lang = 'vi';
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
};

export const setResponseInterceptor = (axios, store) => {
  // Interceptors for response
  axios.interceptors.response.use(
    (resp) => {
      let { data } = resp;
      if (data instanceof Blob) {
        logger(data);
        return data;
      }

      data = data.data;
      logger(data);
      return data;
    },
    async (err) => {
      let responseData;
      if (err.response.data instanceof Blob) {
        const blob = new Blob([err.response.data]);
        const data = await blob.text();
        responseData = JSON.parse(data);
      } else if (!!err.response && !!err.response.data) {
        responseData = err.response.data;
      }

      if (!!responseData && !!responseData.message) {
        switch (responseData.statusCode) {
          case 401:
            store.dispatch(actLogout());
            break;
          case 403:
            history.push(ROUTES.NOT_AUTH);
            break;
          case 404:
            history.push(ROUTES.PAGE_404);
            break;
          case 500:
            history.push(ROUTES.PAGE_500);
            break;
          default:
            break;
        }
        return Promise.reject(responseData);
      } else {
        return Promise.reject(err);
      }
    },
  );
};
