import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../api/authService';

export const actLogin = createAsyncThunk('auth/login', async ({ username, password }) =>
  authService.login(username, password),
);

export const actGetUser = createAsyncThunk('auth/getUser', async () => authService.getUser());

export const actViewAsCompany = createAsyncThunk('auth/viewAsCompany', async ({ companyId }) =>
  authService.viewAsCompany(companyId),
);

export const actUnViewCompany = createAsyncThunk('auth/unViewCompany', async () => authService.unViewCompany());

export const actLogout = createAsyncThunk('auth/logout', async () => authService.logout());

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
  },
  reducers: {
    actUpdateUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actLogin.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(actViewAsCompany.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(actUnViewCompany.fulfilled, (state) => {
      if (!!state.user) {
        state.user.company = null;
      }
    });
    builder.addCase(actGetUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(actLogout.fulfilled, (state) => {
      state.user = null;
    });
  },
});

export const { actUpdateUser } = authSlice.actions;

export default authSlice.reducer;
