import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Helper for populating a JSON with all the translation strings.
const compileStrings = (ctx) => {
  const resources = {};
  // This regex does not work for en-US, en-UK, etc.
  const fileRegex = /(?<locale>\w*)\/(?<fileName>\w+)\.json$/;

  ctx.keys().forEach((file) => {
    const match = file.match(fileRegex);
    if (match) {
      const { fileName, locale } = file.match(fileRegex).groups;
      resources[locale] = !!resources[locale] ? resources[locale] : {};
      resources[locale][fileName] = ctx(file);
    }
  });

  return resources;
};

const resources = compileStrings(require.context('./../assets/i18n/', true, /.*/));

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'vi',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
